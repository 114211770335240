import React, {FunctionComponent, useRef, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {Navigate, useNavigate} from "react-router-dom";
import {EmptyTransCreateModel} from "../models/transactionModel";
import TransCreateStep1 from "../components/transaction/TransCreateStep1";
import TransCreateStep2 from "../components/transaction/TransCreateStep2";
import TransCreateStep3 from "../components/transaction/TransCreateStep3";
import TransCreateStep4 from "../components/transaction/TransCreateStep4";
import {TextTitle} from "../components/Texts";
import {UrlsEnum} from "../enums/urls.enum";
import useCreateTransaction from "../hooks/useCreateTransaction";

const TransCreate: FunctionComponent = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {uploading, error, success, transactionId, submit} = useCreateTransaction();

  const resuming = useRef(transactionId !== null)
  const initialStep = resuming.current ? 3 : 1;

  const [step, setStep] = useState(initialStep);
  const [data, setData] = useState(EmptyTransCreateModel);

  if (success) {
    return <Navigate to={UrlsEnum.ROOT+"#"+transactionId} replace={true}/>
  }

  const onDataChanged = (name: string, value: any) => {
    setData({ ...data, [name]: value })
  }


  const onPrevious = () => {
    const current = step - 1;
    if (current < initialStep) {
        navigate(-1);
    } else {
      setStep(current);
    }
  }

  const onNext = () => {
    const current = step + 1;
    setStep(current);
    if (current === 4) {
      data.price = data.price.replaceAll(",", ".")
      submit(data);
    }
  }



  return (
    <Page>
      <TextTitle>{t( resuming.current ? "transaction.resume.title" : "transaction.create.title")}</TextTitle>
      <Content>
        { step === 1 &&
          <TransCreateStep1 data={data} onPrevious={onPrevious} onNext={onNext} onChange={onDataChanged}/> }
        { step === 2 &&
          <TransCreateStep2 data={data} onPrevious={onPrevious} onNext={onNext} onChange={onDataChanged}/> }
        { step === 3 &&
          <TransCreateStep3 data={data} onPrevious={onPrevious} onNext={onNext} onChange={onDataChanged}/> }
        { step === 4 &&
          <TransCreateStep4 uploading={uploading} error={error} onPrevious={onPrevious}/> }
      </Content>
    </Page>
  )

}

export default TransCreate;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: var(--max-content-width);
  margin: 0 auto;
  padding: 30px 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 750px;
  margin-top: 40px;
  min-height: 410px;
`;

