import React, {FunctionComponent, useEffect, useState} from 'react';
import styled from "styled-components";
import {TransCreateModel} from "../../models/transactionModel";
import {useTranslation} from "react-i18next";
import Toolbar from "../Toolbar";
import InputText from "../InputText";
import {LabelInput} from "../Texts";
import IBAN from "iban";

interface Props {
  data: TransCreateModel
  onPrevious: () => void,
  onNext: () => void,
  onChange: (name: string, value: any) => void
}


interface FieldModel {
  valid: boolean,
  touched: boolean
}

interface StatusModel {
  licensePlates: FieldModel,
  price: FieldModel,
  iban: FieldModel
}

const licensePlatesValidator = (value: string) : boolean => {
  return /^[a-zA-Z0-9]+$/.test(value);
}

const priceValidator = (value: string) : boolean => {
  value = value.trim().replaceAll(",", ".");
  return value.length >0 && !value.endsWith(".") && !isNaN(Number(value));
}

const ibanValidator = (value: string) : boolean => {
  return IBAN.isValid(value);
}

const isNotEmpty = (value: string) : boolean => {
  return value.length > 0;
}

const TransCreateStep2: FunctionComponent<Props> = ( {data, onPrevious, onNext, onChange}) => {

  const { t } = useTranslation();

  const [ status, setStatus ] = useState<StatusModel>({
    licensePlates: { valid: false, touched: false },
    price: { valid: false, touched: false },
    iban: { valid: false, touched: false }
  });

  useEffect(() => {
    setStatus({
      licensePlates: { valid: licensePlatesValidator(data.licensePlates), touched: isNotEmpty(data.licensePlates) },
      price: { valid: priceValidator(data.price), touched: isNotEmpty(data.price) },
      iban: { valid: ibanValidator(data.iban), touched: isNotEmpty(data.iban) }
    });

  }, []);

  const valid = status.licensePlates.valid && status.price.valid && status.iban.valid;


  const onInputChanged = (event: React.ChangeEvent<HTMLInputElement>, valid: boolean) => {
    let field = event.target;
    const { name, value } = field

    setStatus({
      ...status,
      [name]: { valid: valid, touched: true }
    });

    onChange(name, value);
  }


  return (
    <>
      <Container>

        <InputGroup>
          <LabelInput htmlFor="plate">{t("transaction.label.plate")}</LabelInput>
          <InputText
            type="text"
            name="licensePlates"
            placeholder={t("transaction.placeholder.plate")}
            value={ data.licensePlates }
            required={ status.licensePlates.touched }
            onValueChanged={ onInputChanged }
            validator={ licensePlatesValidator }
          />
        </InputGroup>
        <InputGroup>
          <LabelInput htmlFor="price">{t("transaction.label.price.long")}</LabelInput>
          <InputText
            value={ data.price }
            autoComplete={"off"}
            pattern={"([0-9]+)([\\.,][0-9]{1,2})?"}
            type={"text"}
            name="price"
            placeholder={t("transaction.placeholder.price")}
            required={ status.price.touched }
            onValueChanged={ onInputChanged }
            validator={ priceValidator }
          />

        </InputGroup>
        <InputGroup>
          <LabelInput htmlFor="currency">{t("transaction.label.currency")}</LabelInput>
          <InputText
            type="text"
            name="currency"
            placeholder=""
            disabled={ true }
            value={ data.currency }/>
        </InputGroup>
        <InputGroup>
          <LabelInput htmlFor="iban">{t("transaction.label.iban")}</LabelInput>
          <InputText
            type="text"
            name="iban"
            placeholder={t("transaction.placeholder.iban")}
            value={ data.iban }
            required={ status.iban.touched}
            autoComplete={"on"}
            onValueChanged={ onInputChanged }
            validator={ ibanValidator }
          />
        </InputGroup>

      </Container>

      <Toolbar
        margin={"0 0 20px 0"}
        previousClicked = { onPrevious }
        previousDisabled = { false }
        nextClicked = { onNext }
        nextDisabled = { !valid }
      />
    </>


  )
}

export default TransCreateStep2;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;


const InputGroup = styled.div`
  margin-bottom: 20px;
  max-width: 500px;
`;
