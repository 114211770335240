import React from 'react';
import styled from 'styled-components';
import NPButton from "./NpButton";
import Filler from "./Filler";
import {useTranslation} from "react-i18next";

interface Props {
  previousDisabled: boolean,
  previousClicked?: () => void,
  nextVisible?: boolean
  nextClicked?: () => void,
  nextDisabled?: boolean,
  margin?: string;
  width?: string;
}

const Toolbar: React.FunctionComponent<Props> = ({
   previousDisabled,
   previousClicked,
   nextDisabled= false,
   nextClicked = () => {},
   nextVisible = true,
   margin,
   width
}) => {

  const { t } = useTranslation();

  return (
    <Container $margin={margin} $width={width}>
      <NPButton
        onClick={ previousClicked }
        disabled={ previousDisabled }
        label={t('button.label.back')}/>

      { nextVisible &&
        <>
        <Filler/>
        <NPButton
          onClick={ nextClicked }
          disabled={ nextDisabled }
          label={t('button.label.next')}/>
        </>
      }
    </Container>
  );

}

export default Toolbar;

const Container = styled.div<{$margin?: string, $width?: string}>`
  display: flex;
  width: 100%;
  margin: ${props => props.$margin || "0"};
`;
