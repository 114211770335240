export interface LoadStatus {
  loading: boolean;
  success: boolean;
  error: string | null
}

export const StatusInitial: LoadStatus = {
  loading: false,
  success: false,
  error: null
};

export const StatusLoading: LoadStatus = {
  loading: true,
  success: false,
  error: null,
};

export const StatusSuccess: LoadStatus = {
  loading: false,
  success: true,
  error: null
};

export const StatusError = (message?: string) : LoadStatus => {
  return {
    loading: false,
    success: false,
    error: message || "Unknown error"
  }
};

export const canLoad = (status: LoadStatus) => {
  return !status.loading && !status.success && status.error === null;
}

