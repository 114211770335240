import {configureStore} from '@reduxjs/toolkit';
import authReducer from './auth/reducer';
import transactionReducer from './transaction/reducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';


export const Store = configureStore({
  reducer: {
    auth: authReducer,
    transaction: transactionReducer
  },
  devTools: true
});

export type AppDispatch = typeof Store.dispatch;
export type RootState = ReturnType<typeof Store.getState>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
