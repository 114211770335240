import React, {useCallback, useEffect, useRef, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import IconCheck from "../images/svg/check.svg"

interface Props extends React.ComponentPropsWithoutRef<"input"> {
  validator?: (value: string) => boolean
  onValueChanged?: (event: React.ChangeEvent<HTMLInputElement>, valid: boolean) => void
}

const InputText: React.FC<Props> = ({
  name,
  type,
  placeholder,
  value,
  autoComplete = "off",
  pattern = "[a-zA-Z0-9]+",
  disabled = false,
  required= false,
  onValueChanged= (event, valid) => null,
  validator
}) => {

  const [showPassword, setShowPassword] = useState(false);

  const refInput = useRef<HTMLInputElement>(null);

  const togglePassword = () => setShowPassword((state) => !state);

  const validateField = useCallback((field: HTMLInputElement) => {
    let valid = field.checkValidity();
    if (validator) {
      valid = validator(field.value);
      field.setCustomValidity(valid ? "" : "Invalid");
    }
    return valid
  }, [validator]);

  useEffect( () => {
    if (required && refInput.current && refInput.current.value !== "") {
      validateField(refInput.current);
    }
  }, [refInput, required, validateField]);


  const validate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let valid = validateField(event.target as HTMLInputElement);
    onValueChanged(event, valid);
  }

  return (
    <Container>
      <TextField
        ref={refInput}
        id={ name }
        name={ name }
        type={ type !== "password" ? type : !showPassword ? "password" : "text" }
        placeholder={ placeholder }
        value={ value }
        pattern={ pattern }
        disabled={ disabled }
        onChange={ validate }
        onBlur={ validate }
        required={required}
        autoComplete={autoComplete}
      />

      { value !== "" && !disabled &&
        <Check src={IconCheck}/>
      }

      { type === "password" &&
        <ShowPassword
          onClick={ togglePassword }>
          { showPassword && <FontAwesomeIcon icon={ faEye }/> }
          { !showPassword && <FontAwesomeIcon icon={ faEyeSlash }/> }
        </ShowPassword>
      }
    </Container>
  );

}

export default InputText;

const Container = styled.div`
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

const TextField = styled.input`
  margin-top: 10px;
  padding: 10px 15px;
  width: 100%;
  outline: none;
  border-radius: 5px;
  border: 1px solid #E1E1E1;
  background: #FFF;
  //text-transform: uppercase;
  font-size: 16px;
  

  &:focus {
    border-color: var(--color-accent);
    background-color: #FFFFFF;
  }

  &:disabled {
    color: inherit;
    cursor: not-allowed;
    opacity: .7;
  }

  &:invalid {
    border-color: var(--color-complementary);
    
    + img {
      visibility: hidden;
    }
  }
  
  &:valid + img {
    visibility: visible;
  }
`;

const ShowPassword = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  color: grey;
  z-index: 100;
  cursor: pointer;
`;

const Check = styled.img`
  position: absolute;
  top: 15px;
  right: -40px;
  width: 30px;
`;