import {useAppDispatch, useAppSelector} from "../store/store";
import { selectTransactionList } from "../store/transaction/selectors";
import {useEffect} from "react";
import {LIST_TRANSACTIONS} from "../store/transaction/thunk";
import {canLoad} from "../models/load.models";


const useTransactions = () => {

  const dispatch = useAppDispatch();

  const {status, data} = useAppSelector(selectTransactionList)

  useEffect(() => {
    if (canLoad(status)) {
      dispatch(LIST_TRANSACTIONS());
    }
  }, [status, dispatch]);

  return {
    status,
    data
  };
}

export default useTransactions;