import React, {useRef, useState} from "react";
import IconUpload from "../images/svg/upload.svg";
import styled from "styled-components";
import {TextNormal} from "./Texts";
import {faRemove} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
  file?: File,
  onChange: (name: string, value: any) => void
}

const Uploader: React.FunctionComponent<Props> = ({file, onChange}) => {

  const initialPreviewData = file != null ? URL.createObjectURL(file) : null;

  const [previewData, setPreviewData] = useState<string | null>( initialPreviewData)
  const fileInput = useRef<HTMLInputElement>(null);

  const onInputClick = () => {
    fileInput.current?.click();
  }

  const removeFile = () => {
    setPreviewData(null )
    onChange("file", null);
  }

  const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    const file = fileList && fileList[0];
    if (file != null) {
      if (file.type.startsWith("image/")) {
        setPreviewData(URL.createObjectURL(file));
      }
    } else {
      setPreviewData(null )
    }
    onChange("file", file);
  }



  return (
    <Row>
      <Container onClick={onInputClick} $focusable={previewData === null}>
        {previewData &&
        <>
            <Preview src={previewData}/>
            <Delete onClick={removeFile}>
                <FontAwesomeIcon icon={ faRemove } size={"2x"}/>
            </Delete>
        </>}

        {!previewData && <>
            <Image src={IconUpload}/>
            <Upload
                type="file"
                name="upload"
                ref={fileInput}
                onChange={onInputChanged}/>
            <FileName>{file?.name}</FileName>
        </>}

      </Container>

      </Row>
  )
}


const Row = styled.div`
  display: flex;
`;


const Container = styled.div<{$focusable: boolean}>`
  position: relative;
  width: 280px;
  height: 220px;
  border-radius: 5px;
  border: 1px dashed var(--color-accent);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  ${props => props.$focusable && `
    &:hover {
      background-color: rgba(186,240,248,1);
      cursor: pointer;
    }
  `};
  
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
`;


const Upload = styled.input`
  display: none;
`;

const FileName = styled(TextNormal)`
  position: absolute;
  bottom: 2px;
  left: 4px;
  word-break: break-word;
`;

const Delete = styled(TextNormal)`
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 10;
  color: var(--color-accent);
  cursor: pointer;
`;

const Preview = styled.img`
  display: flex;
  margin: 5px;
  height: calc(100% - 10px);
  z-index: 5;
  object-fit:scale-down;
  max-width: 100%;
`;


export default Uploader;
