import { Navigate } from "react-router-dom"
import {UrlsEnum} from "../enums/urls.enum";

interface Props {
  children: React.ReactElement
  authenticated: boolean
}

const ProtectedRoute: React.FC<Props> = ({children, authenticated}) => {
  if (!authenticated) {
    return <Navigate to={UrlsEnum.LOGIN} replace={true} />
  }
  return children
}

export default ProtectedRoute;