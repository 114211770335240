import React, {useState} from "react";
import styled from "styled-components";
import IconCheck from "../images/svg/check.svg";

const computeRipple = (event: React.MouseEvent<HTMLElement>) => {
  // Let's start the ripple!
  // 1. Get the mouse coordinates relative to the button: that's where the ripple is going to start from!
  // 1.1 Get the button
  let button = event.target as HTMLElement;
  let i = 0;
  while (!button.classList.contains('ripple-container') && i < 5) {
    button = button.parentNode as HTMLElement
    i++;
  }

  // 1.2 Get the button coordinates
  let buttonCoord = { x: button.getBoundingClientRect().left, y: button.getBoundingClientRect().top };

  // 1.3 Get the mouse coordinates
  let mouseCoord = { x: event.clientX, y: event.clientY };

  // 1.4. Get the mouse coord relative to the button
  let mouseRelativeCoord = { x: mouseCoord.x - buttonCoord.x, y: mouseCoord.y - buttonCoord.y };

  // 2. Define the ripple coordinates
  // The ripple should have its top left corner where the mouse is, but should rather have its center there
  // so the ripple top left coord will have to be shifted of half its width and height
  // The ripple size is going to be:
  //   - width: half the width of the button
  //   - height: twice the height of the button
  let rippleSize = { width: button.offsetWidth / 2, height: button.offsetHeight * 2 };
  let rippleCoord = { x: mouseRelativeCoord.x - rippleSize.width / 2, y: mouseRelativeCoord.y - rippleSize.height / 2 };
  return { coords: rippleCoord, size: rippleSize };

}

interface Props {
  label: string                     //(MAND) Label of the button
  raised?: boolean                  //(OPT, default false) Set to true if the button should be raised
  filled?: boolean                  //(OPT, default true) Set to false if the button shouldn't have a background (not filled with color)
  outline?: boolean                 //(OPT, default false) Set to true to show the shape of the button. Typically used with filled=false to show an outlined button
  outlineLight?: boolean            //(OPT, default false) Set to true to show the shape of the button in white. Typically used with filled=false to show an outlined button
  secondary?: boolean                //(OPT, default false) Set to true to style it as a secondary button
  highlighted?: boolean              //(OPT, default false) if true, sets this button to be highlighted (kind of selected)
  disabled?: boolean
  color?: string
  width?: string
  checked?: boolean                  //show a check icon and cannot be pressed
  onClick?(e: any): any;
}

const NPButton: React.FC<Props> = ({
   label,
   raised= false,
   filled= true,
   outline= false,
   outlineLight= false,
   secondary= false,
   highlighted= false,
   disabled= false,
   color,
   width,
   checked= false,
   onClick = () => null
}) => {

  const [released, setReleased] = useState(false);
  const [ripple, setRipple] = useState( {coord: {x:0, y:0}, size: {width:0, height:0}});

  const onMouseClick = (e: any) => {
      if (!disabled) {
        onClick(e);
      }
  }

  const onMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    if (!disabled) {
      setReleased(false);
    }
  }

  const onMouseUp = (event: React.MouseEvent<HTMLElement>) => {
    if (!disabled) {
      const {coords, size} = computeRipple(event);
      setReleased(true);
      setRipple({coord: coords, size: size});
    }
  }

  return (
    <Container className="ripple-container"
               $outline={outline}
               $fill={filled}
               $raised={raised}
               $checked={checked}
               $disabled={disabled}
               $width={width}
               onClick={onMouseClick} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseLeave={onMouseUp}>

      {checked &&
        <Check src={IconCheck}/>
      }
      <Button>{label}</Button>

      { released &&
        <Ripple $left={ripple.coord.x}
                $top={ripple.coord.y}
                $width={ripple.size.width}
                $height={ripple.size.height}/>
      }
    </Container>
  )
}

const Container = styled.div<{$outline:boolean, $fill:boolean, $raised:boolean, $disabled:boolean, $checked:boolean, $width?: string}>`
  display: flex;
  align-items: center;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  outline: none;
  text-align: center; 
  
  
  transition-duration: 334ms;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  
  ${props => props.$width && `
    width: ${props.$width};
  `};
  
  ${props => !props.$disabled && `
    cursor: pointer;
  `};

  ${props => !props.$checked && `
    color: white;
  `};
  ${props => props.$checked && `
      color: var(--color-primary);
  `};

  ${props => !props.$outline && `
    padding: 12px 48px;
  `};
  
  ${props => props.$outline && `
    padding: 10px 45px;
  
    ${props.$checked && `
      border: 2px solid var(--color-primary);
      
      ${props.$disabled &&`
         background-color: #E1E1E1;
      `};
    `};
    
    ${!props.$checked && `
      border: 2px solid var(--color-accent);
    `};
  `};
  
  ${props => props.$fill && `
    fill: #2ecafe;
    background-color: #2ecafe;
    
    ${props.$disabled && `
      cursor: not-allowed;
      background-color: rgba(41, 214, 230, 0.40);
    `};
    
    ${!props.$disabled && `
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    
      &:hover {
        transition: box-shadow 0.2s ease-in-out;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
      }
    `};
  `};

  ${props => !props.$fill &&`
    font-weight: bold;
    ${props.$checked &&`
      color: var(--color-primary);   
    `};
    
    ${!props.$checked &&`
      color: var(--color-text-dark);    
      
      &:hover {
        background-color: var(--color-accent);
        color: white;
      }
    `};
 `};
  
  ${props => props.$raised && `
    transition: box-shadow 0.2s ease-in-out;
    
    ${props.$disabled && `
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    `};
    
    ${!props.$disabled && `
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    `};
  `};
  
`;


const Button = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;

  font-family: Righteous;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  font-size: 18px;
`;

const Ripple = styled.div<{$left:number, $top:number, $width:number, $height:number}>`
  position: absolute; /* The absolute position we mentioned earlier */
  border-radius: 50%;
  width: 50%;
  height: 100%;
  transform: scale(0);
  animation: ripple 300ms linear;
  background-color: rgba(255, 255, 255, 0.7);
  
  left: ${props => props.$left}px;
  top: ${props => props.$top}px;
  width: ${props => props.$width}px;
  height: ${props => props.$height}px;

  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
`;

const Check = styled.img`
  width: 20px;
  margin-right: 10px;
`;


export default NPButton

