import axios, { AxiosInstance, AxiosError } from "axios";
import moment from "moment";
import {ErrorModel} from "../models/error.model";

const http: AxiosInstance = axios.create({
  headers: {
    "Accept": "application/json"
  }
});

const generateCorrelationId = () => {
  let ts = moment().format("YYYYMMDDHHmmssSSS");
  let random = (Math.random() * 100000).toFixed(0).padStart(5, "0");
  return ts + "-" + random;
}

http.interceptors.request.use((config) => {
  config.headers!["X-CORRELATION-ID"] = generateCorrelationId();
  return config;
});

http.interceptors.response.use((response) => {
  //return Promise.reject("error test");
  return response;
}, (error) => {
  let reason = decodeError(error)
  return Promise.reject(reason);
});


export const setAuthToken = (token: string|null, provider: string|null): void => {
  if (token) {
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    http.defaults.headers.common["auth-provider"] =  `${provider}`;
  } else {
    delete http.defaults.headers.common["Authorization"];
    delete http.defaults.headers.common["auth-provider"];
  }
};

export default http

export const decodeError = (error: AxiosError) => {
  let reason = error.message;
  if (error.response) {
    reason = error.response.statusText
    let data = error.response.data as ErrorModel
    if (data) {
      reason = data.message;
      if (data.errors && data.errors.length > 0) {
        reason += ": " + data.errors[0];
      }
      if (data.code) {
        reason +=" ("+data.code+")";
      }
      if (data.checks && data.checks.length > 0) {
        reason += " - " + data.checks[0].message + "("+ data.checks[0].code+")";
      }

    }
  }
  return reason;

}
