import {useAppDispatch, useAppSelector} from "../store/store";
import {useCallback, useEffect} from "react";
import {selectTransactionDetail, selectTransactioWorkflow} from "../store/transaction/selectors";
import {LOAD_TRANSACTION, POST_EXCHANGE_CODE, POST_PROPERTY_TRANSFER} from "../store/transaction/thunk";
import {canLoad} from "../models/load.models";
import {useParams} from "react-router-dom";


const isNotNull = (object: unknown) => {
  return object !== undefined && object !== null
}

const useCompleteTransaction = () => {

  const {transactionId} = useParams();


  const dispatch = useAppDispatch();
  const {exchangeStatus, uploadStatus, linkStatus} = useAppSelector(selectTransactioWorkflow)
  const transaction = useAppSelector(selectTransactionDetail)

  const reload  = useCallback( () => {
    if (transactionId) {
      dispatch(LOAD_TRANSACTION(transactionId));
    }
  },[transactionId, dispatch]);
  
  useEffect(() => {
    if (canLoad(transaction.status)) {
      reload();
    }
  }, [transaction.status]);


  const postExchangeCode = useCallback((code: string) => {
    if (transactionId) {
      dispatch(POST_EXCHANGE_CODE({transactionId: transactionId, code: code}));
    }
  }, [transactionId, dispatch])

  const postTransferLink = useCallback((file: File) => {
    if (transactionId) {
      dispatch(POST_PROPERTY_TRANSFER({transactionId: transactionId, file: file}));
    }
  }, [transactionId, dispatch])



  let uploading = uploadStatus.loading || linkStatus.loading;
  let error = transaction.status.error || uploadStatus.error || linkStatus.error || exchangeStatus.error;
  error = error || ""
  let success = !error && uploadStatus.success && linkStatus.success

  const exchangeCode = transaction.data?.exchangeCode || "";
  const counterpartCode = transaction.data?.counterpartECode || "";
  const exchangeCodeUsed = transaction.data?.counterpartInsertedEcode || false
  const confirmationCodeUsed = isNotNull(transaction.data?.counterpartECode) || exchangeStatus.success;
  const propertyTransfer = transaction.data?.propertyTransURL
  const uploadable = exchangeCodeUsed && confirmationCodeUsed && !propertyTransfer

  return {
    loading: transaction.status.loading,
    transaction: transaction.data,

    uploading,
    success,
    error,

    exchangeCode,
    counterpartCode,

    exchangeCodeUsed,
    confirmationCodeUsed,
    uploadable,

    exchangeStatus,
    postExchangeCode,
    postTransferLink,
    reload
  };
}

export default useCompleteTransaction;