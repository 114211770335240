import React, {FunctionComponent} from "react";
import styled from "styled-components";
import IconProgress from "../images/svg/spinner.svg";

interface Props {
  width?: string;
}

const Spinner: FunctionComponent<Props> = ({width = "120px"}) => {

  return (
    <Container $width={width}>
      <Image src={IconProgress}/>
    </Container>
  )

}

export default Spinner;

const Container = styled.div<{$width?: string}>`
  width: ${props => props.$width || "100%"};
  display: flex;
  flex: 1;
  justify-content: center;
  align-items:center;
`;

const Image = styled.img<{$width?: string}>`
  max-width: 100%;
`;
