import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';


i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    debug: false,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    defaultNS: 'translation',
    ns: ['translation'],
    lng: "it",
    fallbackLng: 'en'
  });

export default i18n;