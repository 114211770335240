export const APIS = {
  access: process.env.REACT_APP_ACCESS_API_ENDPOINT,
  profile: process.env.REACT_APP_PROFILE_API_ENDPOINT,
  fs: process.env.REACT_APP_FS_API_ENDPOINT,
  tran: process.env.REACT_APP_TRAN_API_ENDPOINT,
  adminPup: process.env.REACT_APP_ADMIN_PUP_API_ENDPOINT,
  payments: process.env.REACT_APP_PAY_API_ENDPOINT,
  company: process.env.REACT_APP_COMPANY_API_ENDPOINT,
  fees: process.env.REACT_APP_FEES_API_ENDPOINT,
  tranlog: process.env.REACT_APP_TRANLOG_API_ENDPOINT,
  notif: process.env.REACT_APP_NOTIF_API_ENDPOINT,
  auth: process.env.REACT_APP_AUTH_API_ENDPOINT,
  dispute: process.env.REACT_APP_DISPUTE_API_ENDPOINT,
  info: process.env.REACT_APP_INFO_API_ENDPOINT,
  invoice: process.env.REACT_APP_INVOICE_API_ENDPOINT,
  monkey: process.env.REACT_APP_MONKEY_API_ENDPOINT,
}


export const TRACE = {
  STORE: true
};