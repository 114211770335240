import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import IconLogo from "../images/svg/logo-no-color.svg";
import {TextNormal} from "./Texts";
import {Link} from "react-router-dom";

const Footer: FunctionComponent = () => {

  const { t } = useTranslation();

  return (
    <>
      <Line/>
      <Container>
        <WrapperLogo>
          <Logo src={IconLogo}/>
          <Link to={"https://www.pleggit.com"} target={"_blank"}>
            <TextNormal>
              {t('footer.text.visit')}
            </TextNormal>
          </Link>
        </WrapperLogo>
        <TextNormal>
          {t('footer.text.copyright')}
        </TextNormal>
      </Container>
    </>
  )
}
export default Footer

const Line  = styled.hr`
  border-color: #CDCDCD;
  width: 100%;
  margin: 0;
`;

const Container = styled.div`
  max-width: var(--max-content-width);
  width: 100%;
  display: flex;
  margin: 0 auto;
  align-self: center;
  padding: 25px 16px;
  justify-content: space-between;
  align-content: center;
  align-items: center;
`;


const WrapperLogo = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
`;

const Logo = styled.img`
  height: 50px;
  margin-right: 30px;
  fill: var(--color-primary);
`;


