import React, {FunctionComponent} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import PinInput from 'react-pin-input';
import {TextError, TextNormal, TextTitle} from "../components/Texts";
import { Trans } from 'react-i18next';

import ImageConfirm from "../images/codice-conferma.png"
import ImageSecret from "../images/codice-dispositivo.png"
import NPButton from "../components/NpButton";
import Uploader from "../components/Uploader";
import useCompleteTransaction from "../hooks/useCompleteTransaction";
import Spinner from "../components/Spinner";
import IconCheck from "../images/svg/check.svg";
import {Navigate, useNavigate} from "react-router-dom";
import {UrlsEnum} from "../enums/urls.enum";

const spaceAbovePicture = 30;
const spaceBelowPicture = 60;
const pinLength = 4;

const TransComplete: FunctionComponent = () => {

  const { t } = useTranslation();
  const navigate = useNavigate()

  const [pin, setPin] = React.useState("");
  const [file, setFile] = React.useState<File | null>(null);


  const {
    loading, transaction,
    uploadable, uploading,
    error, success,
    exchangeCode, counterpartCode,
    exchangeCodeUsed, confirmationCodeUsed,
    exchangeStatus,
    postExchangeCode, postTransferLink, reload } = useCompleteTransaction()

  if (success) {
    return <Navigate to={UrlsEnum.ROOT+"#"+transaction?.id} replace={true}/>
  }

  const pinValid = pin.length === pinLength;
  const fileValid = file !== null;
  const ready = !loading && transaction;


  const onUploadFileChanged = (name: string, value: any) => {
    setFile(value);
  }

  const onPinChanged = (value: string, index: number) => {
    setPin(value);
  }

  const submitPin = () => {
    if (pinValid) {
      postExchangeCode(pin);
    }
  }

  const submitPropertyTransfer = () => {
    if (fileValid) {
      postTransferLink(file)
    }
  }


  return (
    <Page>
      <TextTitle>{t("transaction.complete.title")}</TextTitle>

      <Table>

        { loading &&
          <Spinner/>
        }

        { ready &&
        <>
          <Column>
            <TextCentered><span><Trans t={t}>{t("transaction.complete.secret.text")}</Trans></span></TextCentered>
            <Margin $margin={spaceAbovePicture}/>
            <Picture src={ImageSecret}/>
            <Margin $margin={spaceBelowPicture}/>
            <TextNormal>{t("transaction.complete.secret.label")}</TextNormal>
            <Margin $margin={12}/>
            <PinInput
                disabled={true}
                type={"custom"}
                regexCriteria={RegExp("[\\s\\S]+")}
                length={pinLength}
                initialValue={exchangeCode}/>
            <Margin $margin={10}/>
            <Bottom>
              {exchangeCodeUsed &&
              <NPButton
                    disabled={true}
                    checked={true}
                    outline={true}
                    filled={false}
                    label={t("transaction.label.activated")}/>
              }
              {!exchangeCodeUsed &&
              <NPButton
                  label={t("button.label.next")}
                  onClick={reload}/>
              }
            </Bottom>
          </Column>

          {exchangeCodeUsed &&
          <Column>
            <TextCentered><span><Trans t={t}>{t("transaction.complete.confirm.text")}</Trans></span></TextCentered>
            <Margin $margin={spaceAbovePicture}/>
            <Picture src={ImageConfirm}/>
            <Margin $margin={spaceBelowPicture}/>
            <TextNormal>{t("transaction.complete.confirm.label")}</TextNormal>
            <Margin $margin={12}/>
            <PinCheck>
                <PinInput
                    initialValue={counterpartCode}
                    disabled={confirmationCodeUsed || counterpartCode !== ""}
                    type={"custom"}
                    length={pinLength}
                    autoSelect={true}
                    onChange={onPinChanged}
                />
              {pin.length === pinLength &&
              <Check src={IconCheck}/>
              }
            </PinCheck>
            <Margin $margin={10}/>

            {exchangeStatus.loading &&
              <Spinner width={"45px"}/>
            }
            <Bottom>
              {!exchangeStatus.loading &&
                <>
                  {(exchangeStatus.success || confirmationCodeUsed) &&
                  <NPButton
                      disabled={true}
                      checked={true}
                      outline={true}
                      filled={false}
                      label={t("transaction.label.activated")}/>
                  }
                  {!exchangeStatus.success && !confirmationCodeUsed &&
                    <NPButton
                        label={t("button.label.confirm")}
                        disabled={!pinValid}
                        onClick={submitPin}/>
                  }
                </>
              }
              </Bottom>
          </Column>
          }


          { uploadable &&
            <Column>
              <TextCentered><span><Trans t={t}>{t("transaction.complete.upload.text")}</Trans></span></TextCentered>
              <Margin $margin={spaceAbovePicture}/>
              <Uploader onChange={onUploadFileChanged}/>
              <Margin $margin={spaceBelowPicture}/>
              {uploading &&
                <Spinner width={"45px"}/>
              }
              {!uploading && !success &&
              <Bottom>
                <NPButton
                    label={t("button.label.confirm")}
                    disabled={!fileValid}
                    onClick={submitPropertyTransfer}/>
              </Bottom>
              }
            </Column>
          }
        </>}

      </Table>

      <Bottom>
          <TextError>{t(error)}&nbsp;</TextError>
      </Bottom>
    </Page>
  )

}

export default TransComplete;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: var(--max-content-width);
  margin: 0 auto;
  padding: 40px 16px;
`;


const Table = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 10px;
  flex-basis: 400px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 60px;
  width: 300px;
  min-height: 475px;
`;


const Picture = styled.img`
  width: 180px;
  height: 125px;
`;

const Margin = styled.div<{$margin: number}>`
  margin-top: ${props => props.$margin}px;
`;

const TextCentered = styled(TextNormal)`
  text-align: center;
  vertical-align: middle;
  min-height: 120px;
  line-height: 120px;

  :first-child {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
`;

const Bottom = styled.div`
  margin-top: auto;
  display: flex;
`;

const PinCheck = styled.div`
  position: relative;  
`;

const Check = styled.img`
  position: absolute;
  top: 10px;
  right: -35px;
  width: 30px;
`;