import {useAppDispatch, useAppSelector} from "../store/store";
import {selectTransactioWorkflow} from "../store/transaction/selectors";
import {CREATE_FULL_TRANSACTION, POST_PROPERTY_PROOF} from "../store/transaction/thunk";
import {TransCreateModel} from "../models/transactionModel";


const useCreateTransaction = () => {

  const dispatch = useAppDispatch();
  const {createStatus, uploadStatus, linkStatus, transactionId} = useAppSelector(selectTransactioWorkflow)


  const submit = (data: TransCreateModel) => {
    if (transactionId) {
      dispatch(POST_PROPERTY_PROOF( {transactionId: transactionId, file: data.file!}));
    } else {
      dispatch(CREATE_FULL_TRANSACTION(data))
    }
  }

  return {
    resuming: transactionId !== null,
    transactionId: transactionId,
    uploading: createStatus.loading || uploadStatus.loading || linkStatus.loading,
    success: createStatus.success && uploadStatus.success && linkStatus.success,
    error: createStatus.error || uploadStatus.error || linkStatus.error,

    submit
  };
}

export default useCreateTransaction;