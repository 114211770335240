import {FunctionComponent} from 'react';
import {Navigate} from "react-router-dom";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from "../store/store";
import { selectAuthStatus } from "../store/auth/selectors";
import { UrlsEnum } from "../enums/urls.enum";
import { LOGIN } from "../store/auth/thunk";
import FormLogin from "../components/FormLogin";
import {UserLogin} from "../models/user.models";
import {TextLarge, TextError} from "../components/Texts";


const Login: FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectAuthStatus)

  if (status.success) {
    return <Navigate to={UrlsEnum.ROOT} replace={true}/>
  }

  const onSubmit = (data: UserLogin) => {
    dispatch(LOGIN(data));
  };

  return (

    <Page>
      <Container>

        <Title>
          {t('login.welcome.label')}<br />
          {t('login.instructions.text')}
        </Title>

        <FormLogin onSubmit={onSubmit} loading={status.loading}/>

        <WrapText>
          <TextError>
            { !status.success && status.error }
          </TextError>
        </WrapText>
      </Container>
    </Page>

  )
}
export default Login;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 auto;
  padding: 40px 16px;
  justify-content: center;
`;



const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background-color: rgba(255, 255, 255, 0.9);

  align-items: center;
  justify-content: center;

  width: 500px;
  padding: 30px 0;
`;

const Title = styled(TextLarge)`
  margin-left: 60px;
  align-self: start;
`;

const WrapText = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  height: 40px;
`;



