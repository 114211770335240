import http from "./http";
import {TransCreateModel} from "../models/transactionModel";

const BASE_URL = process.env.REACT_APP_TRAN_API_ENDPOINT;


const load = (transactionId: string) => {
  return http.get(`/transactions/${transactionId}`, {baseURL: BASE_URL});
};

const list = () => {
  return http.get("/transactions", {baseURL: BASE_URL});
};

const create = (data: TransCreateModel) => {
  return http.post("/transactions", data,{baseURL: BASE_URL});
};

const postPPLink = (transactionId: string, filePath: string )  => {
  return http.post(`/transactions/${transactionId}/docs/property`, { propertyProofLink: filePath} ,{baseURL: BASE_URL});
};

const postExchangeCode = (transactionId: string, code: string )  => {
  return http.post(`/transactions/${transactionId}/exchangeCode`, { counterpartExchangeCode: code} ,{baseURL: BASE_URL});
};

const postPTLink = (transactionId: string, filePath: string )  => {
  return http.post(`/transactions/${transactionId}/docs/transfer`, { propertyTransferLink: filePath} ,{baseURL: BASE_URL});
};

const TransactionService = {
  load,
  create,
  list,
  postPPLink,
  postPTLink,
  postExchangeCode
}

export default TransactionService;