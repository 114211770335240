import React, {useEffect} from 'react';
import Routing from "./routing/Routing";
import {useAppSelector} from "./store/store";
import {selectAuthUser} from "./store/auth/selectors";
import {useNavigate} from "react-router-dom";
import {UrlsEnum} from "./enums/urls.enum";
import Header from "./components/Header";
import Footer from "./components/Footer";
import styled from "styled-components";

function App() {


  const user = useAppSelector(selectAuthUser);
  const navigate = useNavigate();

  const authenticated = user?.accessToken !== undefined;

  /*
  useEffect(() => {
    if (!authenticated) {
      navigate(UrlsEnum.ROOT);
    }
  },[authenticated, navigate])
  */
  //console.log("APP authenticated="+authenticated);

  return (
    <PageWrap>
      <Header border={authenticated}/>
      <Routing authenticated={authenticated}/>
      <Footer/>
    </PageWrap>
  )
}

export default App;


const PageWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;



