import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import {TransCreateModel} from "../../models/transactionModel";
import {useTranslation} from "react-i18next";
import Toolbar from "../Toolbar";
import {LabelForm} from "../Texts";
import Uploader from "../Uploader";
import Filler from "../Filler";


interface Props {
  data: TransCreateModel
  onPrevious: () => void,
  onNext: () => void,
  onChange: (name: string, value: any) => void
}

const TransCreateStep3: FunctionComponent<Props> = ( {data, onPrevious, onNext, onChange}) => {

  const { t } = useTranslation();


  const isValid = data.file !== undefined && data.file !== null;


  return (

    <>
      <Content>
        <Text>{t("transaction.create.step3.text")}</Text>
        <Uploader onChange={onChange} file={data.file }/>
      </Content>
      <Filler/>
      <Toolbar
        margin={"0 0 20px 0"}
        previousClicked = { onPrevious }
        previousDisabled = { false }
        nextClicked = { onNext }
        nextDisabled = { !isValid }/>
    </>

  )
}

export default TransCreateStep3;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 400px;
  padding-bottom: 20px;
`;

const Text = styled(LabelForm)`
  color: rgba(0, 0, 0, 0.80);
  height: 40px;
`;
