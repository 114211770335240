import {FunctionComponent} from "react";
import styled from "styled-components";

const Filler: FunctionComponent = () => {

  return (
    <FlexFill/>
  )

}

export default Filler;

const FlexFill = styled.div`
  flex: 1;
`;
