import {createAction, createAsyncThunk} from '@reduxjs/toolkit';
import { ActionsEnum } from '../../enums/actions.enum';
import {TransCreateModel, DocTypeId, TransactionModel} from "../../models/transactionModel";
import TransactionService from "../../services/TransactionService";
import FileService from "../../services/FileService";



export const LOAD_TRANSACTION = createAsyncThunk(
  ActionsEnum.LOAD_TRANSACTION, async (transactionId: string) => {
    return (await TransactionService.load(transactionId)).data;
  }
);

/*
{
    "id": "6519a83b5c851c7262bd9ab1",
    "creationTime": 20231001191123,
    "partyType": "seller",
    "itemType": "auto",
    "licensePlates": "42423432",
    "price": 4324234,
    "currency": "EUR",
    "deposit": null,
    "iban": "GB33BUKB20201555555555",
    "propertyProofURL": "b100fa3e-705a-497b-a494-deceb2330cb0/6519a83b5c851c7262bd9ab1/propertyproof",
    "propertyTransURL": "b100fa3e-705a-497b-a494-deceb2330cb0/6512349f5c851c726249d8bd/propertyproof",
    "ppOCRPass": false,
    "ppOCRMatches": [],
    "ppOCRConfidence": 0,
    "user": "b100fa3e-705a-497b-a494-deceb2330cb0",
    "state": 2,
    "stateCode": "matching",
    "canCancel": true,
    "canWithdraw": false
}
 */

export const LIST_TRANSACTIONS = createAsyncThunk(
  ActionsEnum.LIST_TRANSACTIONS, async () => {
    return (await TransactionService.list()).data;
  }
);

export const CREATE_TRANSACTION = createAsyncThunk(
  ActionsEnum.CREATE_TRANSACTION, async (payload: TransCreateModel) => {
    payload = { ...payload};
    delete payload.file;
    return (await TransactionService.create(payload)).data;
  }
);
// { "result": "ok",  "transactionId": "6512349f5c851c726249d8bd" }

export const UPLOAD_PROPERTY_DOCUMENT = createAsyncThunk(
  ActionsEnum.UPLOAD_PROPERTY_DOCUMENT,
  async ({ transactionId, docTypeId, file } : { transactionId: string, docTypeId: string, file: File }) => {
    return (await FileService.uploadPropertyDocument(transactionId, docTypeId, file)).data;
  }
);
// { "uploaded": true, "filepath": "b100fa3e-705a-497b-a494-deceb2330cb0/6516373e5c851c726288374e/propertyproof" }


export const POST_PROPERTY_PROOF_LINK = createAsyncThunk(
  ActionsEnum.POST_PROPERTY_PROOF_LINK,
  async ({ transactionId, filePath } : { transactionId: string, filePath: string }) => {
    return (await TransactionService.postPPLink(transactionId, filePath)).data;
  }
);

export const POST_PROPERTY_TRANSFER_LINK = createAsyncThunk(
  ActionsEnum.POST_PROPERTY_TRANSFER_LINK,
  async ({ transactionId, filePath } : { transactionId: string, filePath: string }) => {
    return (await TransactionService.postPTLink(transactionId, filePath)).data;
  }
);
//{cid: "20230930032542051-54771", result: "ok"}

export const POST_EXCHANGE_CODE = createAsyncThunk(
  ActionsEnum.POST_EXCHANGE_CODE,
  async ({ transactionId, code } : { transactionId: string, code: string }) => {
    return (await TransactionService.postExchangeCode(transactionId, code)).data;
  }
);

// chained thunks: reload transaction data too
export const POST_PROPERTY_PROOF = createAsyncThunk(
  ActionsEnum.POST_PROPERTY_PROOF,
  async ({ transactionId, file } : { transactionId: string, file: File }, {dispatch, getState}) => {
    let args = {transactionId: transactionId, docTypeId: DocTypeId.pp, file: file};
    await dispatch(UPLOAD_PROPERTY_DOCUMENT(args)).unwrap()
      .then( async (data: any) => {
        let args = {transactionId: transactionId, filePath: data.filepath};
        await dispatch(POST_PROPERTY_PROOF_LINK(args)).unwrap()
          .then(  () => {
            return dispatch(LOAD_TRANSACTION(transactionId));
          });
      });
  }
);

export const CREATE_FULL_TRANSACTION = createAsyncThunk(
  ActionsEnum.CREATE_FULL_TRANSACTION,
  async (payload: TransCreateModel, {dispatch, getState}) => {
    let file = payload.file!;
    await dispatch(CREATE_TRANSACTION(payload)).unwrap()
      .then( async (data: any) => {
        let args = {transactionId: data.transactionId, file: file};
        return dispatch(POST_PROPERTY_PROOF(args));
      });
  }
);

export const POST_PROPERTY_TRANSFER = createAsyncThunk(
  ActionsEnum.POST_PROPERTY_TRANSFER,
  async ({ transactionId, file } : { transactionId: string, file: File }, {dispatch, getState}) => {
    let args = {transactionId: transactionId, docTypeId: DocTypeId.pt, file: file};
    await dispatch(UPLOAD_PROPERTY_DOCUMENT(args)).unwrap()
      .then( async (data: any) => {
        let args = {transactionId: transactionId, filePath: data.filepath};
        await dispatch(POST_PROPERTY_TRANSFER_LINK(args)).unwrap()
          .then(  () => {
            return dispatch(LOAD_TRANSACTION(transactionId));
          });
      });
  }
);



export const RESTORE_TRANSACTION = createAction<TransactionModel>(ActionsEnum.RESTORE_TRANSACTION);
export const RESET_LOAD_TRANSACTION = createAction(ActionsEnum.RESET_LOAD_TRANSACTION);
export const RESET_LIST_TRANSACTIONS = createAction(ActionsEnum.RESET_LIST_TRANSACTIONS);
export const RESET_CREATE_TRANSACTION = createAction(ActionsEnum.RESET_CREATE_TRANSACTION);








