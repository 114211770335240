import React from 'react';
import styled from 'styled-components';
import {TransactionType} from "../../models/transactionModel";
import {useTranslation} from "react-i18next";

interface Props {
  selected?: boolean,
  transType: TransactionType,
  onSelection: (id: string) => void
}

const CellTransType: React.FunctionComponent<Props> = ({ selected= false, transType, onSelection }) => {

  const { t } = useTranslation();

  const toggleSelection = () => {
    onSelection(selected ? "" : transType.id)
  }

  return (
    <Container>
        <Cell onClick={toggleSelection} $selected={selected}>
          <Half>
            <Icon src={transType.icon}/>
          </Half>
          <Half>
            <Text $light={selected}>{ t(transType.i18n) }</Text>
          </Half>
        </Cell>
    </Container>
  );

}

export default CellTransType;

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 300px;
  height: 130px;
`;

const Cell = styled.div<{$selected: boolean}>`
  display: flex;
  width: 280px;
  flex-direction: row;
  ${props => props.$selected && `
    background-color: var(--color-accent);`
  }
  cursor: pointer;

`;

const Icon = styled.img`
  height: 80px;
`;

const Half = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const Text = styled.div<{$light: boolean}>`
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  color: ${props => props.$light ? "#FFF" : "rgba(0,0,0,0.5)"};


`;