import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux"
import "./index.css";
import App from "./App";
import {Store} from "./store/store";
import "./i18n";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

//     <React.StrictMode> will render 2 times
root.render(
      <Provider store={Store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
);
