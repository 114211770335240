import { createSlice } from '@reduxjs/toolkit';
import {
  LOGIN, LOGOUT
} from './thunk';
import {LoadStatus, StatusError, StatusInitial, StatusSuccess, StatusLoading} from "../../models/load.models";
import {UserModel} from "../../models/user.models";
import {setAuthToken} from "../../services/http";
import {ActionsEnum} from "../../enums/actions.enum";

export interface AuthState {
  status: LoadStatus,
  user: UserModel | null
}


const loadUser = (): UserModel | null => {
  let user = null
  let data = localStorage.getItem("user");
  if (data !== null) {
    try {
      user = JSON.parse(data);
      setAuthToken(user.accessToken, user.userType);
    } catch (err) {
      console.error("failed loading from " + data)
    }
  }
  return user;
}

const saveUser = (user: UserModel) => {
  localStorage.setItem("user", JSON.stringify(user));
  setAuthToken(user.accessToken, user.userType);
}

const clearUser = () => {
  localStorage.removeItem("user");
  setAuthToken(null, null);
}

const initialState: AuthState = {
  status: StatusInitial,
  user: loadUser()
}


const slice = createSlice({
  name: ActionsEnum.SLICE_AUTH,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(LOGIN.pending, (state) => {
      state.status = StatusLoading;
      state.user = null;
    });
    builder.addCase(LOGIN.fulfilled, (state, action) => {
      saveUser(action.payload);
      state.status = StatusSuccess;
      state.user = action.payload;
    });
    builder.addCase(LOGIN.rejected, (state, action) => {
      state.status = StatusError(action.error.message);
    });

    builder.addCase(LOGOUT.fulfilled, (state) => {
      clearUser();
      state.status = StatusInitial
      state.user = null;
    });
  }
});


export default slice.reducer;

