import { createSlice } from '@reduxjs/toolkit';

import {TransactionModel} from "../../models/transactionModel";
import {LoadStatus, StatusInitial, StatusSuccess, StatusLoading, StatusError} from "../../models/load.models";
import {ActionsEnum} from "../../enums/actions.enum";
import {
  CREATE_TRANSACTION,
  UPLOAD_PROPERTY_DOCUMENT,
  POST_PROPERTY_PROOF_LINK,
  POST_PROPERTY_TRANSFER_LINK,
  LOAD_TRANSACTION,
  LIST_TRANSACTIONS,
  RESET_CREATE_TRANSACTION,
  RESET_LIST_TRANSACTIONS,
  RESET_LOAD_TRANSACTION, POST_EXCHANGE_CODE, RESTORE_TRANSACTION,
} from './thunk';
import {TRACE} from "../../Config";


export interface TransactionState {
  workflow: {
    createStatus: LoadStatus,
    uploadStatus: LoadStatus,
    linkStatus: LoadStatus,
    exchangeStatus: LoadStatus,
    transactionId: string | null,
    filePath: string | null,
  },
  listing: {
    status: LoadStatus,
    data: TransactionModel[]
  },
  detail: {
    status: LoadStatus,
    data: TransactionModel | null
  }
  // complete is same of create, reuse the state
}

const InitialWorkflowState =  {
  createStatus: StatusInitial,
  uploadStatus: StatusInitial,
  linkStatus: StatusInitial,
  exchangeStatus: StatusInitial,
  transactionId: null,
  filePath: null
};

const InitialListingState =  {
  status: StatusInitial,
  data: []
};

const InitialDetailState =  {
  status: StatusInitial,
  data: null
};


const InitialState: TransactionState = {
  workflow: InitialWorkflowState,
  listing: InitialListingState,
  detail: InitialDetailState
};


const slice = createSlice({
  name: ActionsEnum.SLICE_TRANSACTION,
  initialState: InitialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(RESET_LIST_TRANSACTIONS.type, (state) => {
      if (TRACE.STORE) console.log("RESET_LIST_TRANSACTIONS")
      state.workflow = InitialWorkflowState;
      state.listing = InitialListingState;
    });

    builder.addCase(LIST_TRANSACTIONS.pending, (state) => {
      if (TRACE.STORE) console.log("LIST_TRANSACTIONS pending");
      state.listing.status = StatusLoading;
    });
    builder.addCase(LIST_TRANSACTIONS.fulfilled, (state, action) => {
      if (TRACE.STORE) console.log("LIST_TRANSACTIONS fulfilled");
      state.listing.status = StatusSuccess;
      state.listing.data = action.payload.transactions;
    });
    builder.addCase(LIST_TRANSACTIONS.rejected, (state, action) => {
      if (TRACE.STORE) console.log("LIST_TRANSACTIONS rejected");
      console.error(action)
      state.listing.status = StatusError(action.error.message);
    });


    builder.addCase(RESET_LOAD_TRANSACTION.type, (state) => {
      if (TRACE.STORE) console.log("RESET_LOAD_TRANSACTION")
      state.detail = InitialDetailState;
    });

    builder.addCase(LOAD_TRANSACTION.pending, (state) => {
      if (TRACE.STORE) console.log("LOAD_TRANSACTION pending");
      state.detail.status = StatusLoading;
    });
    builder.addCase(LOAD_TRANSACTION.fulfilled, (state, action) => {
      if (TRACE.STORE) console.log("LOAD_TRANSACTION fulfilled");
      let transaction = action.payload;

      state.detail.status = StatusSuccess;
      state.detail.data = transaction;

      if (state.listing.data.some( item => item.id === transaction.id)) {
        state.listing.data = state.listing.data.map( item => (item.id === transaction.id) ? transaction : item);
      } else {
        state.listing.data.push(transaction)
      }
      state.detail.data = action.payload;
    });
    builder.addCase(LOAD_TRANSACTION.rejected, (state, action) => {
      if (TRACE.STORE) console.log("LOAD_TRANSACTION rejected");
      console.error(action)
      state.detail.status = StatusError(action.error.message);
    });

    builder.addCase(RESET_CREATE_TRANSACTION.type, (state) => {
      if (TRACE.STORE) console.log("RESET_CREATE_TRANSACTION")
      state.workflow = InitialWorkflowState;
    });
    builder.addCase(CREATE_TRANSACTION.pending, (state) => {
      if (TRACE.STORE) console.log("CREATE_TRANSACTION pending");
      state.workflow.createStatus = StatusLoading;
    });
    builder.addCase(CREATE_TRANSACTION.fulfilled, (state, action) => {
      if (TRACE.STORE) console.log("CREATE_TRANSACTION fulfilled");
      state.workflow.createStatus = StatusSuccess;
      state.workflow.transactionId = action.payload.transactionId
    });
    builder.addCase(CREATE_TRANSACTION.rejected, (state, action) => {
      if (TRACE.STORE) console.log("CREATE_TRANSACTION rejected");
      console.error(action)
      state.workflow.createStatus = StatusError(action.error.message);
    });


    builder.addCase(UPLOAD_PROPERTY_DOCUMENT.pending, (state) => {
      if (TRACE.STORE) console.log("UPLOAD_PROPERTY_DOCUMENT pending");
      state.workflow.uploadStatus = StatusLoading;
    });
    builder.addCase(UPLOAD_PROPERTY_DOCUMENT.fulfilled, (state, action) => {
      if (TRACE.STORE) console.log("UPLOAD_PROPERTY_DOCUMENT fulfilled");
      state.workflow.uploadStatus = StatusSuccess;
      state.workflow.filePath = action.payload.filepath;
    });
    builder.addCase(UPLOAD_PROPERTY_DOCUMENT.rejected, (state, action) => {
      if (TRACE.STORE) console.log("UPLOAD_PROPERTY_DOCUMENT rejected");
      console.error(action)
      state.workflow.uploadStatus = StatusError(action.error.message);
    });


    builder.addCase(POST_PROPERTY_PROOF_LINK.pending, (state) => {
      if (TRACE.STORE) console.log("POST_PROPERTY_PROOF_LINK pending");
      state.workflow.linkStatus = StatusLoading;
    });
    builder.addCase(POST_PROPERTY_PROOF_LINK.fulfilled, (state, action) => {
      if (TRACE.STORE) console.log("POST_PROPERTY_PROOF_LINK fulfilled");
      state.workflow.linkStatus = StatusSuccess;
    });
    builder.addCase(POST_PROPERTY_PROOF_LINK.rejected, (state, action) => {
      if (TRACE.STORE) console.log("POST_PROPERTY_PROOF_LINK rejected");
      console.error(action)
      state.workflow.linkStatus = StatusError(action.error.message);
    });



    builder.addCase(POST_PROPERTY_TRANSFER_LINK.pending, (state) => {
      if (TRACE.STORE) console.log("POST_PROPERTY_TRANSFER_LINK pending");
      state.workflow.linkStatus = StatusLoading;
    });
    builder.addCase(POST_PROPERTY_TRANSFER_LINK.fulfilled, (state, action) => {
      if (TRACE.STORE) console.log("POST_PROPERTY_TRANSFER_LINK fulfilled");
      state.workflow.linkStatus = StatusSuccess;
    });
    builder.addCase(POST_PROPERTY_TRANSFER_LINK.rejected, (state, action) => {
      if (TRACE.STORE) console.log("POST_PROPERTY_TRANSFER_LINK rejected");
      console.error(action)
      state.workflow.linkStatus = StatusError(action.error.message);
    });



    builder.addCase(POST_EXCHANGE_CODE.pending, (state) => {
      if (TRACE.STORE) console.log("POST_EXCHANGE_CODE pending");
      state.workflow.exchangeStatus = StatusLoading;
    });
    builder.addCase(POST_EXCHANGE_CODE.fulfilled, (state, action) => {
      if (TRACE.STORE) console.log("POST_EXCHANGE_CODE fulfilled");
      state.workflow.exchangeStatus = StatusSuccess;
    });
    builder.addCase(POST_EXCHANGE_CODE.rejected, (state, action) => {
      if (TRACE.STORE) console.log("POST_EXCHANGE_CODE rejected");
      console.error(action)
      state.workflow.exchangeStatus = StatusError(action.error.message);
    });

    builder.addCase(RESTORE_TRANSACTION, (state, action) => {
      if (TRACE.STORE) console.log("RESTORE_TRANSACTION")
      state.workflow.createStatus = StatusSuccess;
      state.workflow.transactionId = action.payload.id;
      state.workflow.exchangeStatus = StatusInitial
      state.detail.status = StatusInitial
      state.detail.data = null
    });


  }
});



export default slice.reducer;

