export enum ActionsEnum {
  // Auth
  LOGIN = "Auth/Login",
  LOGOUT = "Auth/Logout",
  // Transactions
  RESET_LOAD_TRANSACTION = "Transaction/ResetLoad",
  LOAD_TRANSACTION = "Transactions/Load",

  RESET_LIST_TRANSACTIONS = "Transaction/ResetList",
  LIST_TRANSACTIONS = "Transactions/List",

  RESET_CREATE_TRANSACTION = "Transaction/ResetCreate",
  CREATE_TRANSACTION = "Transaction/Create",
  CREATE_FULL_TRANSACTION = "Transaction/CreateFull",

  UPLOAD_PROPERTY_DOCUMENT = "Transaction/UploadPropertyDocument",

  POST_PROPERTY_PROOF_LINK = "Transaction/PostPropertyLink",
  POST_PROPERTY_TRANSFER_LINK = "Transaction/PostTransferLink",

  POST_PROPERTY_PROOF    = "Transaction/UploadPropertyProofAndPostLink",
  POST_PROPERTY_TRANSFER = "Transaction/UploadPropertyTransferAndPostLink",

  POST_EXCHANGE_CODE = "Transaction/PostExchangeCode",

  RESTORE_TRANSACTION = "Transaction/Restore",
  COMPLETE_TRANSACTION = "Transaction/Complete",

  SLICE_AUTH = "Auth",
  SLICE_TRANSACTION = "Transaction"

}