import React, {FunctionComponent} from 'react';
import styled from "styled-components";
import Toolbar from "../Toolbar";
import IconProgress from "../../images/svg/spinner.svg";
import {TextError} from "../Texts";


interface Props {
  uploading: boolean,
  error: string | null
  onPrevious: () => void,
}

const TransCreateStep4: FunctionComponent<Props> = ( {uploading, error, onPrevious}) => {


  return (

    <Container>

      <Content>
        <div>
          {uploading &&
          <Image src={IconProgress}/>
          }

          {error &&
          <TextError>
            {error}
          </TextError>
          }
        </div>
      </Content>

      <Toolbar
          margin={"0 0 20px 0"}
          previousClicked = { onPrevious }
          previousDisabled = { uploading }
          nextVisible = { false }/>
    </Container>


  )
}

export default TransCreateStep4;


const Container = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


const Image = styled.img`
  width: 120px;
  height: 120px;
`;


