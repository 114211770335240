import React from 'react';
import styled from 'styled-components';
import {useAppDispatch, useAppSelector} from "../store/store";
import NPButton from "./NpButton";
import IconLogo from "../images/svg/logo.svg";
import IconPleggit from "../images/svg/pleggit-title.svg";
import {selectAuthUser} from "../store/auth/selectors";
import {useTranslation} from "react-i18next";
import {LOGOUT} from "../store/auth/thunk";
import {useNavigate} from "react-router-dom";
import {UrlsEnum} from "../enums/urls.enum";

interface Props {
  border?: boolean,
}

const Header: React.FunctionComponent<Props> = ({border = true}) => {

  const user = useAppSelector(selectAuthUser);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate()


  const showLogout = user != null

  const gotoHome = () => {
    navigate(UrlsEnum.ROOT)
  }

  const logout = () => {
    dispatch(LOGOUT())
  }

  return (
    <>
      <Container>
        <Centered>
          <Logo src={IconLogo}/>
          <Pleggit src={IconPleggit} onClick={gotoHome}/>
        </Centered>
        {showLogout &&

          <NPButton
            outline={true}
            filled={false}
            onClick={logout}
            label={t("button.label.logout")}/>

        }
      </Container>
      { border && <Line/>}
    </>

);

}

export default Header;

const Container = styled.div`
  max-width: var(--max-content-width);
  width: 100%;
  display: flex;
  margin: 25px auto;
  align-self: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const Centered = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 50px;
  margin-right: 20px;
`;

const Pleggit = styled.img`
  height: 40px;
  cursor: pointer;
`;


const Line  = styled.hr`
  border-color: #CDCDCD;
  width: 100%;
  margin: 0;
`;