import React from 'react';
import styled from 'styled-components';
import {TransactionModel, TransactionStates} from "../../models/transactionModel";
import {TextSmall, TextLarge, TextLargeStrong} from "../Texts";
import {useTranslation} from "react-i18next";


const decodeState = (state: number) => {
  return TransactionStates[state-1];
}

const decodeCurrency = (currency: string) => {
  switch (currency) {
    case "EUR": return "€";
    default: return currency;
  }
}


interface Props {
  transaction: TransactionModel,
  onAction: (transaction: TransactionModel) => void
}


const TransListItem: React.FunctionComponent<Props> = ({transaction, onAction}) => {

  const { t } = useTranslation();

  const onActionClicked = () => {
    onAction(transaction);
  }

  let action = null;
  if (transaction.state === 1 && !transaction.propertyProofURL) {
    action = "transaction.action.property.proof";
  } else if (transaction.state === 4) {
    action = "transaction.action.property.transfer";
  }
  //if (data.state === 2 || data.

  return (
    <Container id={transaction.id}>
      <Cell $width={"150px"} $minWidth={"80px"}>
          <TextSmallUpper>{t("transaction.label.plate")}</TextSmallUpper>
          <TextLarge>{transaction.licensePlates}</TextLarge>
      </Cell>
      <Cell $width={"150px"} $minWidth={"100px"}>
        <TextSmallUpper>{t("transaction.label.price")}</TextSmallUpper>
        <TextLarge>{decodeCurrency(transaction.currency)} {transaction.price.toLocaleString()}</TextLarge>
      </Cell>
      <Cell $width={"200px"} $minWidth={"120px"}>
        <TextSmallUpper>{t("transaction.label.status")}</TextSmallUpper>
        <TextLarge>{t(decodeState(transaction.state))}</TextLarge>
      </Cell>
        <Cell $width={"auto"} $clickable={action !== null} $minWidth={"400px"} onClick={onActionClicked}>
          {action && <>
            <TextSmallUpper>{t("transaction.label.action")}</TextSmallUpper>
            <TextLargeStrong>{t(action)}</TextLargeStrong>
          </>}
        </Cell>
    </Container>
  );

}

export default TransListItem;

const TextSmallUpper = styled(TextSmall)`
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const Container = styled.div`
  width: calc(100% - 10px);
  border-radius: 9px;
  border: 1px solid #E1E1E1;
  background: #FFF;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;



const Cell = styled.div<{$width: string, $clickable?: boolean, $minWidth?: string}>`
  display: flex;
  flex-direction: column;
  width: ${props => props.$width};
  margin: 24px;
  min-width: ${props => props.$minWidth || "auto"};
  ${props => props.$clickable && `
    &:hover {
      cursor: pointer;
    }
  `};
`;


