import http from "./http";

const BASE_URL = process.env.REACT_APP_FS_API_ENDPOINT;


const uploadPropertyDocument = ( transactionId: string, docTypeId: string, file: File) => {
  const data = new FormData()
  data.append("file", file);
  return http.post(`/files/transactions/${transactionId}/${docTypeId}`, data,{baseURL: BASE_URL});
};

const FileService = {
  uploadPropertyDocument
}

export default FileService;