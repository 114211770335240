import {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import InputText from "../components/InputText";
import NPButton from "../components/NpButton";
import {UserLogin} from "../models/user.models";
import {ConstantsEnum} from "../enums/constants.enum";
import {LabelInput} from "./Texts";
import Spinner from "./Spinner";

const emailValidator = (value: string) : boolean => {
  return new RegExp(ConstantsEnum.EMAIL_REGEX).test(value);
}

const passwordValidator = (value: string) : boolean => {
  return value.length > 0;
}



interface Props {
  loading: boolean,
  onSubmit: (data: UserLogin) => void
}
const FormLogin: FunctionComponent<Props> = ({loading, onSubmit}) => {

  const { t } = useTranslation();

  const [ status, setStatus ] = useState({
    email: {value: "", valid: false, touched: false},
    password: {value: "", valid: false, touched: false}
  });


  const valid = status.email.valid && status.password.valid;



  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (valid) {
       onSubmit( {username: status.email.value, password: status.password.value, userType: ConstantsEnum.USER_TYPE} );
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>, valid: boolean) => {
    let field = event.target;
    setStatus({
      ...status,
      [field.name]: { value: field.value, valid: valid, touched: true}
    });
  }

  return (
    <Form onSubmit={ onFormSubmit }>
      <InputGroup>
        <LabelInput htmlFor="email">{t("login.label.username")}</LabelInput>
        <InputText
          type="email"
          name="email"
          placeholder={t("login.placeholder.username")}
          value={ status.email.value }
          pattern={ConstantsEnum.EMAIL_REGEX}
          validator={emailValidator}
          required={status.email.touched}
          onValueChanged={ onInputChange }/>
      </InputGroup>

      <InputGroup>
        <LabelInput htmlFor="password">{t("login.label.password")}</LabelInput>
        <InputText
          type="password"
          name="password"
          placeholder={t("login.placeholder.password")}
          validator={passwordValidator}
          value={ status.password.value }
          required={status.password.touched}
          onValueChanged={ onInputChange }/>
      </InputGroup>

      { loading && <Spinner width={"50px"}/> }
      { !loading &&
      <NPButton
        onClick={ onFormSubmit }
        disabled={!valid || loading}
        label={t('button.label.login')}/>
      }
    </Form>
  )
}
export default FormLogin;


const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 420px;
  margin-top: 40px;
  min-height: 270px;
`;

const InputGroup = styled.div`
  margin-bottom: 40px;
  width: 90%;
` ;

