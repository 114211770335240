import { createAsyncThunk } from '@reduxjs/toolkit';

import { ActionsEnum } from '../../enums/actions.enum';
import {UserLogin} from "../../models/user.models";
import AuthService from "../../services/AuthService";
import {RESET_LIST_TRANSACTIONS} from "../transaction/thunk";

export const LOGIN = createAsyncThunk(
  ActionsEnum.LOGIN,
  async (payload: UserLogin) => {
    return (await AuthService.login(payload)).data;
  }
);

export const LOGOUT = createAsyncThunk(
  ActionsEnum.LOGOUT,
   async (data,{dispatch}) => {
     await dispatch(RESET_LIST_TRANSACTIONS());
     return Promise.resolve();
  }
);



