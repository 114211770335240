import React from "react";
import {Routes, Route} from "react-router-dom";
import {UrlsEnum} from "../enums/urls.enum";

import Login from "../pages/Login";
import TransList from "../pages/TransList";
import TransCreate from "../pages/TransCreate";
import TransComplete from "../pages/TransComplete";
import ProtectedRoute from "./ProtectedRoute";
import NotFound from "../pages/NotFound";

interface Props {
  authenticated: boolean
}


const Routing: React.FunctionComponent<Props> = ( {authenticated}) => {

  return (
    <Routes>
      <Route path={UrlsEnum.LOGIN} element={ <Login/> } />

      <Route path={UrlsEnum.ROOT}
             element={ <ProtectedRoute authenticated={authenticated}><TransList   /></ProtectedRoute> }
      />
      <Route path={UrlsEnum.TRANSACTION_CREATE}
             element={ <ProtectedRoute authenticated={authenticated}><TransCreate /></ProtectedRoute> }
      />
      <Route path={UrlsEnum.TRANSACTION_COMPLETE+"/:transactionId"}
             element={ <ProtectedRoute authenticated={authenticated}><TransComplete/></ProtectedRoute> }
      />

      <Route path="*" element={<NotFound/>}/>
    </Routes>
  )

}

export default Routing;