import React, {FunctionComponent} from 'react';
import styled from "styled-components";
import {TransactionTypes, TransCreateModel} from "../../models/transactionModel";
import {useTranslation} from "react-i18next";
import CellTransType from "../transaction/CellTransType";
import Toolbar from "../Toolbar";
import {LabelForm} from "../Texts";
import Filler from "../Filler";

interface Props {
  data: TransCreateModel
  onPrevious: () => void,
  onNext: () => void,
  onChange: (name: string, value: any) => void
}

const TransCreateStep1: FunctionComponent<Props> = ( {data, onPrevious, onNext, onChange}) => {

  const { t } = useTranslation();

  const onSelectionChanged = (typeId: string) => {
    onChange("itemType", typeId);
  }


  const cells = TransactionTypes.map( (transType, index) => {
    return (
      <CellTransType key={index} transType={transType} selected={transType.id === data.itemType} onSelection={onSelectionChanged}/>
    )
  });

  const valid = data.itemType !== "" ;

  return (
    <>
      <Text>{t("transaction.create.step1.text")}</Text>

      <Table>{cells}</Table>
      <Filler/>
      <Toolbar
        margin={"0 0 20px 0"}
        previousClicked = { onPrevious }
        previousDisabled = { false }
        nextClicked = { onNext }
        nextDisabled = { !valid }
      />

    </>
  )
}

export default TransCreateStep1;


const Text = styled(LabelForm)`
  color: rgba(0, 0, 0, 0.80);
  margin-left: 25px;
  height: 40px;
`;


const Table = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: start;
  max-width: 600px;
  width: 100%;
  
`;

