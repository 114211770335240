import styled from "styled-components";

const TextPoppins = styled.div<{$color?: string}>`
  color: ${props => props.$color || "#000"};
  font-family: Poppins;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
`;

export const TextTitle = styled(TextPoppins)<{$color?: string}>`
  color: ${props => props.$color || "#000"};
  font-size: var(--font-xlarge);
  font-weight: 700;
`;


export  const TextSmall = styled(TextPoppins)<{$color?: string}>`
  font-size: var(--font-small);
`;

export const TextNormal = styled(TextPoppins)<{$color?: string}>`
  font-size: var(--font-normal);
`;

export const TextLarge = styled(TextPoppins)<{$color?: string}>`
  font-size: var(--font-large);
`;

export const TextLargeStrong = styled(TextLarge)<{$color?: string}>`
  font-weight: 700;
`;




const LabelPoppins = styled.label<{$color?: string}>`
  color: ${props => props.$color || "#000"};
  font-family: Poppins;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
`;


export const LabelForm = styled(LabelPoppins)<{$color?: string}>`
  font-size: 14px;
`;

export const LabelInput = styled(LabelPoppins)<{$color?: string}>`
  margin-left: 20px;
`;


export const TextError = styled(TextNormal)`
  color: var(--color-complementary);
`;
