import React, {FunctionComponent} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import useTransactions from "../hooks/useTransactions";
import TransListItem from "../components/transaction/TransListItem";
import {TextLarge, TextTitle, TextError, TextNormal} from "../components/Texts";
import Spinner from "../components/Spinner";
import IconNew from "../images/svg/plus.svg";
import {useNavigate} from "react-router-dom";
import {UrlsEnum} from "../enums/urls.enum";
import {useAppDispatch} from "../store/store";
import {
  RESET_CREATE_TRANSACTION,
  RESET_LIST_TRANSACTIONS,
  RESTORE_TRANSACTION
} from "../store/transaction/thunk";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import {TransactionModel} from "../models/transactionModel";
import useScrollToAnchor from "../hooks/useScrollToAnchor";

const TransList: FunctionComponent = () => {

  useScrollToAnchor();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { status, data } = useTransactions()

  const reload = () => {
    dispatch(RESET_LIST_TRANSACTIONS());
  }

  const gotoCreateTransaction = () => {
    dispatch(RESET_CREATE_TRANSACTION())
    navigate(UrlsEnum.TRANSACTION_CREATE)
  }

  const gotoRestoreTransaction = (transaction: TransactionModel) => {
    dispatch(RESTORE_TRANSACTION(transaction))
    navigate(UrlsEnum.TRANSACTION_CREATE)
  }

  const gotoCompleteTransaction = (transaction: TransactionModel) => {
    dispatch(RESTORE_TRANSACTION(transaction))
    navigate(UrlsEnum.TRANSACTION_COMPLETE+"/"+transaction.id)
  }

  const continueTransaction = (transaction: TransactionModel) => {
    if (transaction.state === 1 && !transaction.propertyProofURL) {
      gotoRestoreTransaction(transaction);
    } else if (transaction.state === 4) {
      gotoCompleteTransaction(transaction)
    }
  }


  const items = data.map( (transModel, index) => {
    return (
      <TransListItem key={index} transaction={transModel} onAction={continueTransaction}/>
    )
  });


  return (
    <Page>
      <Head>
        <Title>{t("transaction.list.title")}</Title>
        <Reload onClick={reload}>
          <FontAwesomeIcon icon={faRefresh} size={"2x"}/>
        </Reload>
      </Head>

      {status.success &&
      <>
        {items.length === 0 &&
          <Centered>
            <TextNormal>{t("transaction.list.empty")}</TextNormal>
          </Centered>}

        {items.length > 0 &&
          <Table>
            {items}
          </Table>}

          <Button onClick={gotoCreateTransaction}>
              <Image src={IconNew}/>
              <TextLarge>{t("transaction.create.title")}</TextLarge>
          </Button>
      </>}

      {status.loading &&
        <Centered>
            <Spinner />
        </Centered>}

      {!status.success &&
        <Centered>
          <TextError>
            {status.error}
          </TextError>
        </Centered>}
    </Page>
  )
}
export default TransList;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: var(--max-content-width);
  margin: 0 auto;
  padding: 30px 16px;
  width: 100%;
`;

const Title = styled(TextTitle)`
  width: var(--max-content-width);
`;

const Table = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 20px;
  max-height: 480px;
  overflow: auto;
`;


const Button = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  margin-top: auto;


  &:hover {
    background-color: rgba(186,240,248,1);
  }
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  margin: 20px;
`;

const Centered  = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Head = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

`;

const Reload = styled.div`
  margin-right: 20px;
  cursor: pointer;
  color: var(--color-accent);

`;