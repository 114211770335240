import {FunctionComponent} from "react";
import styled from "styled-components";

const NotFound: FunctionComponent = () => {
  return (
      <Page>
        <Container>
          404
        </Container>
      </Page>

  );
}
export default NotFound

const Page = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: clamp(400px, 45vw, 500px);
  border-radius: 5px;
  padding: 5vw;
  border: 1px solid #e8e8e8;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
`;
